class WindowObserver {

  constructor() {
    this.init = this.init.bind(this);
  }

  init() {
    window.addEventListener("resize", () => {
      if (Math.abs(this.lastHeight - window.innerHeight) < 0.10 * this.lastHeight) {
        return;
      }
      this.lastHeight = window.innerHeight;
      window.dispatchEvent(new CustomEvent("layout-resize"));
    });
  }
}

export default WindowObserver;