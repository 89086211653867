module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Agnieszka Stankiewicz Photography","short_name":"AS Photo","start_url":"/","background_color":"#f7f0eb","theme_color":"#feffff","display":"standalone","icon":"static/png/ikonka.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"http://www.photography-dubai.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
